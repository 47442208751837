.shops-container {
  display: flex;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid #7482bb;
  border-bottom-style: dashed;
  border-width: 2px;
  width: 100%;
}
.shops-information {
  width: 80%;
  margin-left: 10px;
}
.title {
  margin-bottom: 10px;
}
.subtitle {
  margin: 0;
}
.transform-title {
  text-transform: capitalize;
}
.icon-payments-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-payments-container:hover {
  cursor: pointer;
}

.payment-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: solid white 1px;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  background-color: #5e9f72;
  margin-right: 0.2em;
}

.payment-image:hover {
  cursor: pointer;
}

.payment-text {
  margin: auto;
  font-size: 1em;
  font-weight: bold;
  padding: 5px 0;
  text-align: center;
}

@media (max-width: 575.98px) {
  .payment-text {
    text-align: center;
    margin: auto;
  }
}

@media (max-width: 773px) {
  .icon-payments-container {
    flex-direction: column;
    justify-content: center;
  }
}

.send-item-icon {
  color: rgba(0, 0, 0, 0.5);
}
