.contenedor{
    position: relative;
    /* display: inline-block; */
    text-align: center;
}
.texto-encima{
    position: absolute;
    top: 15%;
    font-size: 70px;
    color:"#79B279"
}
