

.resume {
    min-height: 300px;
    padding: 5rem 0;
}

.resume .tag{
    padding: 8px 12px;
    margin-bottom: 24px;
    margin-top: -24px;
    border-radius: 24px;
}

/* item paid  */

.resume .tag-paid{
    color: #FFFFFF;
    background-color: #02B875;
}

.resume .tag-paid .icon{
    float: left;
    margin: 4px;
    width: 16px;
    height: 16px;
    background-image: url(../../assets/images/round-payment_paid-24px.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.resume .tag-paid span{
    display: block;
    float: right;
    height: 16px;
    margin: 4px 8px;
    line-height: 100%;
    text-transform: uppercase;
    font-size: .95rem;
    font-family: 'ProductSansBold', sans-serif;
    letter-spacing: .08rem;
}

/* Item Received */
.resume .tag-received {
    color: #FFFFFF;
    background-color: #49BDF6;
}
.resume .tag-received.loan {
    background-color: #a03737;
}

.resume .tag-received .icon {
    float: left;
    margin: 4px;
    width: 16px;
    height: 16px;
    background-image: url(../../assets/images/round-payment_received-24px.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.resume .tag-received span {
    display: block;
    float: right;
    height: 16px;
    margin: 4px 8px;
    line-height: 100%;
    text-transform: uppercase;
    font-size: .95rem;
    font-family: 'ProductSansBold', sans-serif;
    letter-spacing: .08rem;
}

/* end item received */

.resume .subtitle {
    margin: .5rem 1rem;
    font-family: 'San Francisco', 'Roboto', 'ProductSans', sans-serif;
    text-transform: uppercase;
    letter-spacing: .1rem;
}

.resume .amount {
    margin: 0 auto .5rem auto;
    font-family: 'San Francisco', 'Roboto', 'ProductSans', sans-serif;
    font-size: 3.6rem;
}

.resume .date{
    font-size: 1.3rem;
    color: #FFFFFF;
    letter-spacing: .05rem;
}

.list-item-details{
    padding: 0;
    width: 100%;
    min-height: 80px;
}

.list-item-details .main-item{
    list-style: none;
    display: block;
    height: 96px;
    padding: 0 0;
    margin: 0 15px 24px 15px;
    overflow: hidden;
    transition: all .3s ease-in-out;
    border-bottom: 1px solid #F3F2FF;
}
.list-item-details .main-item .main-user-img{
    box-sizing: border-box;
    float: left;
    width: 56px;
    height: 56px;
    margin: 20px 16px 20px 0;
    border: 1px solid #FFFFFF;
    border-radius: 32px;
    background-color: #1C11A8;
    overflow: hidden;
}
.list-item-details .main-item .main-box-info{
    float: left;
    height: 96px;
    padding: 20px 0 20px 0;
    width: calc(100% - 72px);
}
.list-item-details.main-item .main-box-info .text{
    float: left;
    height: 56px;
    width: calc(100% - 40px);
    padding: 0;
}
.list-item-details .main-item .main-box-info .text .title{
    display: block;
    font-size: 1.25rem;
    color: #000000;
}
.list-item-details .main-item .main-box-info .text .subtitle{
    display: block;
    width: 100%;
    height: 24px;
    color: rgba(0, 0, 0, 0.54);
    overflow: hidden;
    font-size: .9rem;
}
.list-item-details .main-item .main-box-info .action{
    float: right;
    height: 56px;
    padding: 16px 0 16px 16px;
    color: #FFFFFF;
}

.item-message{
    list-style: none;
}

.box-message{
    padding: 16px;
    background-color: #F3F2FF;
    border-radius: 16px;
}

.box-message > .text > .title{
    color: #8784ed;
    font-size: 1rem
}

.box-message > .text > p{
    color: #3728de;
    margin: 0;
    font-size: 1.15rem;
}
.item-quota{
    height: 80px;
    list-style: none;
    margin-bottom: 16px;
}

.box-quota{
    height: 80px;
    background-color: #f4f2ff;
    border-radius: 16px;
}

.box-quota > .action {
    display: block;
    float: right;
    width: 48px;
    height: 80px;
    padding: 24px 8px;
}

.box-quota > .action > i{
    padding: 4px;
    border-radius: 24px;
    color: #3828de;
    cursor: pointer;
}

.box-quota > .text {
    display: block;
    float: left;
    width: calc(100% - 48px);
    height: 80px;
    padding: 16px 16px;
}

.box-quota > .text > .title{
    display: block;
    color: #8784ed;
    font-size: 1rem
}

.box-quota > .text > .quota-selection{
    display: block;
    color: #3728de;
    margin: 0;
    font-size: 1.15rem;
    font-family: 'ProductSansBold', sans-serif
}


@media (max-width: 575.98px) {
    .list-transactions .item-transaction .box-transaction > .box-img{
        display: none;
    }
}