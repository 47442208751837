.modal-header .btn-close{
  margin: 0 auto;
  width: auto;
  position: absolute;
  top: 5px;
  right: 1px;
  color: #fff;
  text-decoration: none;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 2px 10px 0px 10px;
  background-color: #3A4D9F !important;
  transition: all 0.3s ease;
  
}
.modal-header .btn-close::before{
  content: 'X';
  font-weight: bold;
}
 
.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: none;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}