
.item-palta-user {
    display: block;
    width: 100%;
    height: 72px;
    padding-right: 15px;
    padding-left: 15px;
    list-style: none;
    list-style-type: none;
    transition: all .25s ease-in-out;
    cursor: pointer;
    cursor: hand;
}

  .item-palta-user:hover {
    border-radius: 8px;
    background-color: rgba(215, 214, 250, 0.14);
}

.list-palta .item-palta-user:active,
.list-palta .item-palta-user:focus {
    border-radius: 8px;
    background-color: rgba(215, 214, 250, 0.24);
    box-shadow: 0 8px 16px rgba(215, 214, 250, 0.54);
    outline: none;
}

.list-palta .item-palta-user>.box-transaction {
    display: block;
    width: 100%;
    height: 72px;
    border-bottom: 1px solid rgba(215, 214, 250, 0.36);
}

.list-palta .item-palta-user:hover>.box-transaction {
    border-bottom: 1px solid transparent;
}

.list-palta .item-palta-user:last-child>.box-transaction {
    border-bottom: 1px solid transparent;
}

.list-palta .item-palta-user .box-transaction>.box-img {
    display: block;
    float: left;
    width: 48px;
    height: 72px;
    margin: 0;
    padding: 16px 8px 16px 0;
}

.list-palta .item-palta-user .box-transaction .box-img>img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #221886;
    overflow: hidden;
}

.list-palta .item-palta-user .box-transaction>.text {
    display: block;
    float: left;
    height: 72px;
    padding: 16px 8px;
    width: calc(100% - 50px);
    overflow: hidden;
}

.list-palta .item-palta-user .box-transaction .text>h5 {
    margin: 0;
    font-size: 1rem;
    color: rgba(0, 0, 0, .8);
}

.list-palta .item-palta-user .box-transaction .text>.username {
    color: rgba(0, 0, 0, .7);
}

.list-palta .item-palta-user .box-transaction>.infoAmount {
    display: block;
    float: right;
    height: 72px;
    padding: 16px 0 16px 8px;
    text-align: right;
}

.list-palta .item-palta-user .box-transaction .infoAmount>.date {
    display: block;
    color: rgba(0, 0, 0, 0.54);
    font-size: .9rem;
}

.list-palta .item-palta-user .box-transaction .infoAmount>.amount {
    display: block;
    font-size: 1rem;
    font-family: 'San Francisco', 'Roboto', 'ProductSans', sans-serif
}

.list-palta .item-palta-user .box-transaction .infoAmount>.received {
    color: #49BDF6
}

.list-palta .item-palta-user .box-transaction>.quota {
    float: right;
    display: block !important;
    text-align: center !important;
    height: 72px;
    padding: 16px 0;
    margin: 0 24px;
    font-family: 'San Francisco', 'Roboto', 'ProductSans', sans-serif;
}

.list-palta .item-palta-user .box-transaction .quota>.title {
    display: block;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: .05rem;
    font-size: .8rem;
    color: rgba(0, 0, 0, .54);
    line-height: 1.35rem;

}

.list-palta .item-palta-user .box-transaction .quota>.number {
    display: block;
}

 /* CONTENT  */

.content {
     height: 64px;
     width: 100%;
     color: #171717;
 }

.content .left {
    display: block;
    float: left;
    height: 64px;
    padding: 20px 0px;
    font-size: 1.2rem;
}

.content .right {
    display: block;
    float: right;
    width: 100px;
    height: 64px;
    padding: 20px 0;
    font-size: 1.2rem;
    text-align: right;
    color: #171717 !important;
}

.item-palta-user.received > .content > .left {
    color: #49BDF6 !important;
}
.item-palta-user.received > .content > .right {
    color: #49BDF6 !important;
}

.item-palta-user.received:hover {
    border-radius: 8px;
    background-color: #EAF8FF !important;
}

.item-palta-user.received:active,
.item-palta-user.received:focus {
    border-radius: 8px;
    background-color: #EAF8FF !important;
    box-shadow: 0 8px 16px rgba(71, 190, 246, 0.14) !important;
    outline: none;
}


/*******************************************************
Settigs Content List
*******************************************************/

.item-resume>.content>.left {
    display: block;
    width: 100%;
    float: left;
    height: 64px;
    padding: 20px 0px;
    font-size: 1.2rem;
}

.item-resume>.content>.right {
    display: block;
    float: right;
    width: 100px;
    height: 64px;
    padding: 20px 0;
    font-size: 1.2rem;
    text-align: right;
    color: #171717;
}

.item-resume {
    display: block;
    height: 64px;
    padding: 0 15px;
}

.item-resume>.content {
    height: 64px;
    width: 100%;
    border-bottom: 1px solid #ececfc;
}

.item-resume:last-child .content {
    border-bottom: 1px solid transparent;
}

.item-received>.content {
    color: #49BDF6;
}

.item-received>.content>.right {
    color: #49BDF6;
}

.item-received:hover {
    border-radius: 8px;
    background-color: #EAF8FF;
}

.item-received:active,
.item-received:focus {
    border-radius: 8px;
    background-color: #EAF8FF;
    box-shadow: 0 8px 16px rgba(71, 190, 246, 0.14);
    outline: none;
}