.box-breadcrumbs {
    padding: 0.5rem 0 0 0;
    background-color: #FFFFFF;
}

.box-breadcrumbs-dark {
    background-color: #3A4D9F;
    border-bottom: none;
}

.box-breadcrumbs-cyan {
    background-color: #EAF8FF;
    border-bottom: 1px solid #D2EEFD;
}

.box-breadcrumbs-green {
    border-bottom: 1px solid rgba(0, 117, 74, 0.24);
}

.box-breadcrumbs-red {
    background-color: #e25b5b;
    border-bottom: 1px solid rgba(0, 117, 74, 0.24);
}

.breadcrumbs {
    box-sizing: border-box;
    display: block;
    float: left;
    width: max-content;
    height: 42px;
    padding: 10px 0;
    border-radius: 8px;
    color: #807dec;
    background-color: rgba(255, 255, 255, .0);
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.breadcrumbs:hover {
    color: #3728de;
}

.breadcrumbs:focus,
.breadcrumbs:active {
    background-color: #f4f2ff;
    color: #3728de;
    padding: 10px 20px 10px 12px;
    margin-left: -12px;
    margin-right: 0px;
}

.breadcrumbs-dark {
   color: #FFFFFF;;
}

.breadcrumbs-dark:hover {
    color: #FFFFFF;
}

.breadcrumbs-dark:focus,
.breadcrumbs-dark:active {
    background-color: #3A4D9F;
    color: #FFFFFF;
}

.breadcrumbs-cyan {
    color: #71CAF7;
}

.breadcrumbs-cyan:hover {
    color: #49BDF6;
}

.breadcrumbs-cyan:focus,
.breadcrumbs-cyan:active {
    background-color: #49BDF6;
    color: #FFFFFF !important;
}

.breadcrumbs-green {
    color: rgba(0, 117, 74, 0.8);
}

.breadcrumbs-green:hover {
    color: #FFFFFF;
}

.breadcrumbs-green:focus,
.breadcrumbs-green:active {
    background-color: #00754a;
    color: #FFFFFF !important;
}

.breadcrumbs-red {
    color: rgba(0, 117, 74, 0.8);
}

.breadcrumbs-red:hover {
    color: #FFFFFF;
}

.breadcrumbs-red:focus,
.breadcrumbs-red:active {
    background-color: #00754a;
    color: #FFFFFF !important;
}

.breadcrumbs .icon {
    display: block;
    float: left;
    margin-right: 8px;
}

.breadcrumbs .month {
    display: block;
    float: left;
}

.breadcrumbs-title {
    display: block;
    width: auto;
    height: 42px;
    float: left;
    font-size: 1rem;
    border-left: 1px solid #e1e0fb;
    padding: 12px 16px;
    margin-left: 16px;
    color:#3A4D9F
}

.breadcrumbs-title-dark {
    color: rgba(255, 255, 255, 1);
    border-left: 1px solid rgba(161, 159, 242, .54);
}

.breadcrumbs-title-cyan {
    color: #49BDF6;
    border-left: 1px solid #71CAF7;
}

.breadcrumbs-title-green {
    color: #00754a;
    border-left: 1px solid rgba(0, 117, 74, 0.24);
}

.breadcrumbs-title-red {
    color: rgba(255, 255, 255, 1);
    border-left: 1px solid rgba(0, 117, 74, 0.24);
}