.modal-header .close{
  margin: 0 auto;
  width: auto;
  position: absolute;
  top: 5px;
  right: 1px;
  color: #fff;
  text-decoration: none;
  border: 3px solid #fff;
  border-radius: 50%;
  padding: 1px 8px 6px 8px;
  background-color: #3A4D9F !important;
  transition: all 0.3s ease;
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: none;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: inherit;
}