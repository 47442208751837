.radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
  }
  
  .radio-item input[type='radio'] {
    display: none;
  }
  
  .radio-item label {
    color: #666;
    font-weight: normal;
  }
  
  .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #004c97;
    background-color: transparent;
  }
  
  .radio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #004c97;
  }

  .button-confirm{
    background-color:#3A4D9F !important;
    color: white;
  }
  .button-confirm:hover{
    color: white;
  }
  .button-cancel{
    background-color:#BD2130 !important;
    color: white;
  }
  .button-cancel:hover{
    color: white;
  }
  .lenghtSelect{
    width: 40% !important;
  }
  .input-alias{
    text-transform: uppercase;

  }