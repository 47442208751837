.container.h-payments-group {
  margin-bottom: 24px;
  padding-top: 1rem;
  background-color: #f6f6f9;
  border-radius: 16px;
  overflow: hidden;
}

.container.h-payments-group-pay {
  background-color: #f3f2ff;
}

.box-main-item-pay {
  display: block;
  float: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 20px;
}

.box-main-item-pay > .divider {
  display: block;
  border-right: 1px solid #d3d3f9;
  width: 1px;
  height: 140px;
  float: right;
  margin-left: 16px;
}

.main-item-pay {
  display: block;
  width: 100px;
  height: 140px;
  padding: 16px;
  float: left;
  border-radius: 16px;
  color: #3A4D9F;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: 0 4px 16px rgba(55, 40, 222, 0.14);
  margin: 0;
}

.main-item-pay .info {
  height: 47px;
}

.main-item-pay .info > span {
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  line-height: 100%;
  overflow: hidden;
  text-align: center;
}

.main-item-pay .icon {
  position: relative;
  display: block;
  height: 54px;
  width: 54px;
  margin: 0 auto 8px auto;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.icon .add-new {
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 30px;
  border: 3px solid #fff;
  border-radius: 50%;
}

.row-payments-items {
  width: calc(100% - 141px);
  float: right;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: calc(24px - 0.3rem);
  padding-left: calc(17px - 0.3rem);
  -webkit-overflow-scrolling: touch;
}
.row-payments-items::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #d4d4f9;
}

.row-payments-items::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: transparent;
}

.row-payments-items::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

.row-payments-items::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #d4d4f9;
}

.row-payments-items .box-item-payment {
  display: inline-block;
  float: none;
  width: 112px;
  height: 140px;
  margin: 0 0.3rem;
  padding: 16px;
  border-radius: 16px;
  color: #3A4D9F;
  background-color: rgba(255, 255, 255, 0.75);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(55, 40, 222, 0.14);
}

.row-payments-items .box-item-payment:hover,
.main-item-pay:hover {
  box-shadow: 0 0.8rem 1.5rem rgba(54, 39, 221, 0.18) !important;
}

.row-payments-items .box-item-payment:active {
  margin: 5px 0.5rem;
  height: 130px;
  box-shadow: 0 1rem 1.8rem rgba(54, 39, 221, 0.24) !important;
}

.main-item-pay:active {
  margin: 5px 0;
  height: 130px;
  box-shadow: 0 1rem 1.8rem rgba(54, 39, 221, 0.24) !important;
}

.box-item-payment .user-img {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0 auto 12px auto;
  background-color: #3A4D9F;
  background-position: center;
  background-size: cover;
}

.box-item-payment .icon {
  position: relative;
  display: block;
  height: 54px;
  width: 54px;
  margin: 0 auto 8px auto;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.box-item-payment .info {
  height: 47px;
}

.box-item-payment .info > span {
  color: rgba(0, 0, 0, 0.8);
  width: 80px;
  height: auto;
  max-height: 47px;
  margin: 0;
  line-height: 110%;
  text-align: center;
  box-sizing: border-box;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.95rem;
}

.h-payments-group > .row-payments-title {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.box-payments-title {
  padding: 0 24px;
}

.box-payments-title > h3 {
  margin: 0;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.54);
}
.box-payments-title > .payments-title-pay {
  color: #3A4D9F;
}

.payment-shop-item {
  border-radius: 16px;
  padding: 14px;
  margin-right: 8px;
  width: 112px;
  background-color: white;
  display: inline-block;
  box-shadow: 0 4px 16px rgba(55, 40, 222, 0.14);
  cursor: pointer;
}

.payment-shop-item-text {
  font-size: 13px;
  text-align: center;
  margin-top: 12px;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

@media (max-width: 575.98px) {
  .list-transactions .item-transaction .box-transaction > .box-img {
    display: none;
  }
}
