.modal-content-spinner{
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;

  
  }

.modal-content-spinner .modal-content {
    border:none !important;
    background-color: transparent !important;
  }
  .modal-body {
    margin: auto 10px !important;
  }
  .style-container{
    background-color: white;
    border-radius: 60%;
    padding: 15px;
    /* border-top: 10px solid #57B576;
    border-bottom: 10px solid #57B576;
    background-image: linear-gradient(#57B576,#387650), linear-gradient(#57B576,#387650 );
    background-size: 10px 100%;
    background-position: 0 0, 100% 0;
    background-repeat: no-repeat; */
  }
  