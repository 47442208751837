.box-quota {
    height: 80px;
    background-color: #f4f2ff;
    border-radius: 16px;
}

.box-quota>.action {
    display: block;
    float: right;
    width: 48px;
    height: 80px;
    padding: 24px 8px;
}

.box-quota>.action>i {
    padding: 4px;
    border-radius: 24px;
    color: #3828de;
    cursor: pointer;
}

.box-quota>.text {
    display: block;
    float: left;
    width: calc(100% - 48px);
    height: 80px;
    padding: 16px 16px;
}

.box-quota>.text>.title {
    display: block;
    color: #8784ed;
    font-size: 1rem
}

.box-quota>.text>.quota-selection {
    display: block;
    color: #3728de;
    margin: 0;
    font-size: 1.15rem;
    font-family: 'ProductSansBold', sans-serif;
    font-weight: bold;
}

.box-quota>.text> p {
    display: block;
    color: #3728de;
    margin: 0;
    font-size: 1rem;
    font-family: 'ProductSansBold', sans-serif;
}