.data-label {
  font-size: 12px;
  color: rgba(20, 20, 20, 0.6);
}

.data {
  font-size: 16px;
  color: rgba(20, 20, 20, 0.8);
  margin-bottom: 16px;
}
