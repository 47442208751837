.nav-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  padding-top: 3px;
}

.nav-link-container span {
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
}

.nav-link-container i {
  font-size: 28px !important;
}

.nav-link-container div {
  width: 60px;
  font-size: 10px;
  text-align: center;
}

.btnColor{
  background-color:#3A4D9F !important;
  border-color:#3A4D9F 
}
.colorFont{
  color:#3A4D9F !important
}