.box-user-img{
    padding: 1rem 0 3rem 0;
}

.user-img{
    display: block;
    width: 96px;
    height: 96px;
    margin: 0.5rem auto 2rem auto;
    border-radius: 48px;
    border: 2px solid #807cec;
    background-color: #1C11A8;
    overflow: hidden;
}