.data-wapper {
  display: flex;
  justify-content: space-between;
}

.data-amount-label {
  font-size: 1.2rem;
  color: #212529;
  font-weight: bold;
}

.data-amount {
  font-size: 1.2rem;
  color: #3A4D9F;
  font-weight: bold;
}
