
.input-container {
  position: relative;
  
}
.input-class:-webkit-autofill,
.input-class:-webkit-autofill:hover, 
.input-class:-webkit-autofill:focus, 
.input-class:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.input-class {
  border: 0;
  border-bottom: 2px solid #9e9e9e;
  outline: none;
  transition: .2s ease-in-out;
  box-sizing: border-box;
 
}
.input:-webkit-autofill {
  color: #2a2a2a !important;
}

.label-text {
  top: 0;
  left: 0; right: 0;
  color: #26a69a;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  cursor: text;
  transition: .2s ease-in-out;
  box-sizing: border-box;
}
.label-text-select {
  top: -7px;
  left: 0; right: 0;
  color: #26a69a;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  cursor: text;
  transition: .2s ease-in-out;
  box-sizing: border-box;
  padding-bottom: 10px;
    

}
.label-phone{
  top: -20px;
  left: 0; right: 0;
  color: #26a69a;;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  cursor: text;
  transition: .2s ease-in-out;
  box-sizing: border-box;
  padding-bottom: 10px;
}
.label-user-origenIng{
  top: -2px;
  left: 13px;
  right: 0;
  color: #26a69a;;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1rem;
  cursor: text;
  transition: .2s ease-in-out;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.input-class,
label {
  width: 100%;
  height: 2.5rem;
  font-size: 1rem;
}

/* Interation */
.input-class:valid,
.input-class:focus {
  border: 1px solid #26a69a !important; 
  border-radius: 8px; 
}

.input-class:valid + label,
.input-class:focus + label {
  color: #26a69a;
  font-size: 1rem;
  top: -30px;
  pointer-events: none;
}

.react-tel-input .form-control {

  border-bottom-color: #CACACA !important;
}
.position-logo {
position: relative;
top: -69px;
right: 0;
bottom: 0;
left: 0px;
}
.bold{
color: #3A4D9F;
}

.carduser {
/* width: 150px;
height: 150px; */
background-color: #fff;
margin: 20px;
border-radius: 10px;
box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
position: relative;
cursor: pointer !important;
z-index: 1;
border-radius: 10px;
transition: all ease 0.2s;
}
.carduser-invalid {
/* width: 150px;
height: 150px; */
background-color: #fff;
margin: 20px;
border-radius: 10px;
box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
position: relative;
z-index: 1;
border-radius: 10px;
transition: all ease 0.2s;
}
.carduser:hover .badgenen{
visibility: hidden;
}
/* .carduser:hover {
box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
transform: translateY(-0.9rem);
} */
.carduser:hover {
top: -3px;
box-shadow: 3px 3px 15px 0px rgba(82, 82, 82, 0.85);
}
.carduser:active {
top: 2px;
background-color: #93BFA1;
box-shadow: inset 0 0 10px rgba(82, 82, 82, 0.85);
}
.position-logo-palta{
position: relative;
top: 0;
right: 0;
bottom: 0;
left: 0px;

}
.position-logo-palta-form-user{
position: relative;
top: 10px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-align-user{
position: relative;
top: 88px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-align-bene-mza-act-user {
position: relative;
top: -6px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-align-bene-mza-act-commerce{
position: relative;
top: 145px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-align-register-initial{
position: relative;
top: -29px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-align-bene-mza-act-commerce-apod {
position: relative;
top: 43px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-register-form{
position: relative;
top: 80px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-success{
position: relative;
top: 47px;
right: 0;
bottom: 0;
left: 0px;
}
.phone-field {
margin: 50px;
font-size: 20px;
}
.phone-input {
width: 46px;
text-align: center;
font-size: 65px !important;
height: 1.75em;
border: 0;
outline: 0;
background: transparent;
border-bottom: 2px solid #ddd;
margin-right: 2px;
margin-left: 2px;
color: #3A4D9F;
}
.position-button-phone {
position: relative;
top: 120px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-commerce{
position: relative;
top: 264px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-commerce-tit {
position: relative;
top: 67px;
right: 0;
bottom: 0;
left: 0px;
}


.position-button-commerce-apod{
position: relative;
top: -20px;
right: 0;
bottom: 0;
left: 0px;
}
.position-button-align-bene-mza-act-commerce-finish {
position: relative;
top: 198px;
right: 0;
bottom: 0;
left: 0px;
}


.mati-button-class{
background: crimson;
}
.position-sms{
position: relative;
top: -103px;
right: 0;
bottom: 0;
left: 0px;
}
.position-sms-button{
position: relative;
top: -103px;
right: 0;
bottom: 0;
left: 0px;
}
.position-validation-identity{
position: relative;
top: -17px;
right: 0;
bottom: 0;
left: 0px;
}
#personalized_button {
width: 217px;
height: 45px;
border-radius: 5px;
background-color: #3A4D9F;
color: #fff;
font-family: inherit;
font-size: 18px;
}

#mati-button{
display: none;
}