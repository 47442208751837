.container-img{
    /* background-color: red; */
    border-radius: 60%
}
.position-boton-right{
    position: relative;
    top: 137px;
    right: 0;
    bottom: 0;
    left: 241px;
}
.position-boton-left{
    position: relative;
    top: 107px;
    right: 0;
    bottom: 0;
    left: 33px;
}
.img-rotate-logo{ 
    width: 80px;
    height: 80px;
    position: relative;
    top:0; right:0; bottom:0;
    animation: rotation 2s; 
  }
  .img-logo {
    width: 140px;
    height: 140px;
    margin-top: 10px;
}
  @keyframes rotation {
    100%{ transform:rotatey(360deg); }
  }
  .text-colors{
    color: #3A4D9F
  }
  .button-color-next{
    color:#5E9F72
  }
  .container-img{
    width: 140px;
    height: 140px;
  }