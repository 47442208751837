
h2.title{
    color: #3A4D9F;
} 

.list-menu{
    padding: 0;
    width: 100%;
    min-height: 80px;
    
}

.list-menu .main-item{
    list-style: none;
    display: block;
    height: 96px;
    padding: 0 15px;
    margin-bottom: 24px;
    border-radius: 8px;
    overflow: hidden;
    transition: all .3s ease-in-out;
    border-bottom: 1px solid #7482BB !important; 
     border-style: dashed;
}
.list-menu .main-item .main-user-img{
    box-sizing: border-box;
    float: left;
    width: 56px;
    height: 56px;
    margin: 20px 16px 20px 0;
    border: 1px solid #3728de;
    border-radius: 32px;
    background-color: #FFFFFF;
    overflow: hidden;
}
.list-menu .main-item .main-box-info{
    float: left;
    height: 96px;
    padding: 20px 0 20px 0;
    width: calc(100% - 72px);
    border-bottom: 1px solid #FFFFFF;
}
.list-menu .main-item .main-box-info .text{
    float: left;
    height: 56px;
    width: calc(100% - 40px);
    padding: 0;
}
.list-menu .main-item .main-box-info .text .title{
    display: block;
    font-size: 1.25rem;
    color: #FFFFFF;
}
.list-menu .main-item .main-box-info .text .subtitle{
    display: block;
    width: 100%;
    height: 24px;
    color: rgba(255, 255, 255, 0.80);
    overflow: hidden;
    font-size: .9rem;
}
.list-menu .main-item .main-box-info .action{
    float: right;
    height: 56px;
    padding: 16px 0 16px 16px;
    color: #FFFFFF;
}

.list-menu .item{
    display: block;
    height: 70px;
    padding: 0 15px;
    transition: all .3s ease-in-out;
   
}

.list-menu .item .box-icon{
    float: left;
    display: inline-block;
    width: 48px;
    height: 64px;
    padding: 15px 24px 20px 0;
}

.list-menu .item .box-icon i{
    color: #3A4D9F;
}

.list-menu .item .box-info{
    display: inline-block;
    float: left;
    width: calc(100% - 48px);
    height: 64px;
    border-bottom: 1px solid #FFFFFF;
}

.list-menu .item .box-info .text{
    float: left;
    display: inline-block;
    width: calc(100% - 40px);
    height: 64px;
    font-weight: bold;
    font-size: 20px;
    color: #3A4D9F;
    padding: 20px 0;
}

.list-menu .item .box-info .action{
    display: inline-block;
    float: right;
    height: 64px;
    width: 40px;
    padding: 20px 0 20px 16px;
    color: #3A4D9F;
}

.list-menu .main-item:focus,
.list-menu .item:focus{
    background: #6E65E8;
    outline: none;
}

.list-menu .main-item:focus > .box-info,
.list-menu .main-item:active > .box-info{
    border-color: transparent;
}

.list-menu .main-item:active,
.list-menu .item:active{
    background: #FFFFFF;
    outline: none;
    box-shadow: 0 12px 20px 0 rgba(0,0,0,0.20);
}

.list-menu .item .box-info{
    display: inline-block;
    float: left;
    width: calc(100% - 48px);
    height: 64px;
    border-bottom: 1px solid #FFFFFF;
}

.list-menu .item:last-child > .box-info{
    border-bottom: 1px solid transparent;
}

