

.light {
  background-color: #ffffff;
}

h2.title {
  color: #fafafa;
}

.resume {
  min-height: 150px;
  padding: 2rem 0;
}

.resume .amount {
  margin: 0 auto 0.5rem auto;
  font-family: 'San Francisco', 'Roboto', 'ProductSans', sans-serif;
  font-size: 3.6rem;
}

.resume .subtitle {
  margin: 0.5rem 1rem;
  font-family: 'San Francisco', 'Roboto', 'ProductSans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

/*******************************************************
Settigs List
*******************************************************/
.list-resume {
  list-style-type: none;
  padding: 0;
}
.item-resume {
  display: block;
  height: 64px;
  padding: 0 15px;
  transition: all 0.3s ease-in-out;
}
.item-resume > .content {
  height: 64px;
  width: 100%;
  color: #171717;
  border-bottom: 1px solid #ececfc;
}

.item-resume:last-child .content {
  border-bottom: 1px solid transparent;
}

.item-resume:hover {
  border-radius: 8px;
  background-color: rgba(215, 214, 250, 0.14);
}

.item-resume:active,
.item-resume:focus {
  border-radius: 8px;
  background-color: rgba(215, 214, 250, 0.24);
  box-shadow: 0 8px 16px rgba(215, 214, 250, 0.54);
  outline: none;
}

.item-resume > .content > .left {
  display: block;
  width: calc(100% - 100px);
  float: left;
  height: 64px;
  padding: 20px 0px;
  font-size: 1.2rem;
}

.item-resume > .content > .right {
  display: block;
  float: right;
  width: 100px;
  height: 64px;
  padding: 20px 0;
  font-size: 1.2rem;
  text-align: right;
  color: #171717;
}

.item-resume {
  display: block;
  height: 64px;
  padding: 0 15px;
}
.item-resume > .content {
  height: 64px;
  width: 100%;
  border-bottom: 1px solid #ececfc;
}

.item-resume:last-child .content {
  border-bottom: 1px solid transparent;
}

.item-received > .content {
  color: #49bdf6;
}

.item-received > .content > .right {
  color: #49bdf6;
}

.item-received:hover {
  border-radius: 8px;
  background-color: #eaf8ff;
}

.item-received:active,
.item-received:focus {
  border-radius: 8px;
  background-color: #eaf8ff;
  box-shadow: 0 8px 16px rgba(71, 190, 246, 0.14);
  outline: none;
}
.box-item-card {
  transition: 0.1s 0.1s ease-out !important;
}
.isActive > .box-item-card {
  background-color: #3628dd;
  color: #ffffff !important;
}

.isActive > .box-item-card h5,
.isActive > .box-item-card i {
  color: #ffffff !important;
}

.wallet-item {
  background-color: #c0c4e2;
  color: #3a4d9f;
  padding: 30px;
  border-radius: 16px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 1.25rem;
  margin-top: 15px;
  height: 20px;
  line-height: 0;
  font-weight: bold;
  font-family: 'ProductSans';
}

.wallet-item-active {
  color: #ffffff;
  background-color: #3a4d9f;
  cursor: default;
  padding: 30px;
  padding-left: 10px;
  font-family: 'ProductSans';
  margin-bottom: 15px;
}

.transaction-items-wapper {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  border-bottom: 1px solid #7482bb;
  border-bottom-style: dashed;
  border-width: 2px;
}

.transaction-items-wapper:hover {
  background-color: rgba(215, 214, 250, 0.14);
}

.transaction-item-left {
  color: grey;
}


.transaction-item-right-mobile {
  text-align: right;
  font-size: 15px;
  font-weight: bold;
  font-family: 'ProductSans';
  width: 140px;
  margin-right: 12px;
}


.transaction-item-title {
  color: #3a4d9f !important;
  padding: 0px;
  margin: 0px;
  font-size: 17px;
  font-weight: bold;
  font-family: 'ProductSans';
  width: 166px;
  text-transform: capitalize;
}

@media (min-width: 600px) {
  .transaction-item-title {
    width: 300px;
    text-transform: capitalize;
  }
}

.transaction-item-subtitle {
  color: #3a4d9f !important;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
}
.transaction-item-date {
  color: #98a6cd;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
}
.transaction-item-primary {
  color: #3628dd;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
}

.modal-item-detail {
  display: flex;
  justify-content: space-between;
}
#content {
  position: relative;
}

.ribbon {
  position: absolute;
  top: -38px;
  right: -50px;
  background-color: coral;
}
.ribbon-mobile {
  position: absolute;
  top: -6px;
  right: 0px;
}

@media (max-width: 600px) {
  .lenghtlabel {
    width: 125% !important;
  }
  .react-datepicker__input-container{
 
    width: 100% !important;
  

}
.react-datepicker-wrapper{
  width: 80% !important;
}

}


.lenghtlabel {
  width: 100% ;
}
/* .butn:hover {background-color: #3e8e41 !important}

.butn:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
} */
@media (max-width: 1920px) {

  .pageNumbers {
    list-style: none;
    display: flex;
    padding: 25px 0px;
    justify-content: center;
    color: #3D4899;
  }
  .pageNumbers > li > button,
  .pageNumbers > li >  {
    width: 100%;
    margin: 0;
    line-height: 40px;
    padding: 3px;
    text-decoration: none;
    -webkit-transition-duration: 0.4s; 
    transition-duration: 0.4s;
    border-radius: 10px;
  }

  .pageNumbers > li {
    float: right;
    width: 3%;
    margin-top: -1px;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .pageNumbers> li {
    width: 15%;
  }
}
@media (max-width: 320px) {
  .pageNumbers > li {
    width: 50%;
  }
}
@media (min-width: 640px) {

  .pageNumbers {
    width: 100%;
    margin-top: 10px;
    display: none;
    padding: 25px 0px;
  }
  .pageNumbers li button:hover {
    color: #3D4899;
  }
  
  .pageNumbers > li {
    float: right;
    width: 10%;
    margin-top: -1px;
    text-align: center;
  }
}
.pageNumbers {
  display: inline-flex !important;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 12px 0px;
  margin: 0px;

}

.pageNumbers > li {
  display: inline;
  display: table-cell;
  background-color: transparent;
  /* border-radius: 10px; */
  border: 1px solid #dee2e6;
 
}
.pageNumbers > li > button,
.pageNumbers > li {
  -webkit-transition-duration: 0.4s; 
  transition-duration: 0.4s;
  /* width: 100%; */
  text-align: center;
  background-color: transparent;

  ;
} 
.pageNumbers li button {
  background-color: transparent;
  border: none;
  color: #3D4899;;
  font-size: 1.5rem;
  cursor: pointer;
}

.pageNumbers li button:focus {
  outline: none;
}  
.pageNumbers li.active {
  background-color: #5E9F72;
  color: black;
}
.disable-color{
  background-color: #C0C4E2;
}
