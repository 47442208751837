.box-item-dashboard.limit {
  background-color: #3628dd;
  color: #fff;
}

.box-item-dashboard.limit h5 {
  color: #fff;
}

.box-item-dashboard.received {
  background-color: #49bdf6;
  color: #ffffff;
}

.box-item-dashboard.received h5 {
  color: #ffffff;
}

.list-transactions {
  display: block;
  height: auto;
  min-height: 72px;
  padding-bottom: 2rem;
  list-style-type: none;
  -webkit-margin-start: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
}
.list-transactions .item-transaction {
  display: block;
  width: 100%;
  height: 72px;
  padding-right: 15px;
  padding-left: 15px;
  list-style: none;
  list-style-type: none;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  cursor: hand;
}
.list-transactions .item-transaction:hover {
  border-radius: 8px;
  background-color: rgba(215, 214, 250, 0.14);
}

.list-transactions .item-transaction:active,
.list-transactions .item-transaction:focus {
  border-radius: 8px;
  background-color: rgba(215, 214, 250, 0.24);
  box-shadow: 0 8px 16px rgba(215, 214, 250, 0.54);
  outline: none;
}

.list-transactions .item-transaction > .box-transaction {
  display: block;
  width: 100%;
  height: 72px;
  border-bottom: 1px solid rgba(215, 214, 250, 0.36);
}

.list-transactions .item-transaction:hover > .box-transaction {
  border-bottom: 1px solid transparent;
}

.list-transactions .item-transaction:last-child > .box-transaction {
  border-bottom: 1px solid transparent;
}
.list-transactions .item-transaction .box-transaction > .box-img {
  display: block;
  float: left;
  width: 48px;
  height: 72px;
  margin: 0;
  padding: 16px 8px 16px 0;
}
.list-transactions .item-transaction .box-transaction .box-img > img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #221886;
  overflow: hidden;
}
.list-transactions .item-transaction .box-transaction > .text {
  display: block;
  float: left;
  height: 72px;
  padding: 16px 8px;
}
.list-transactions .item-transaction .box-transaction .text > h5 {
  margin: 0;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.8);
}
.list-transactions .item-transaction .box-transaction .text > .username {
  color: rgba(0, 0, 0, 0.7);
}
.list-transactions .item-transaction .box-transaction > .info {
  display: block;
  float: right;
  height: 72px;
  padding: 16px 0 16px 8px;
  text-align: right;
}
.list-transactions .item-transaction .box-transaction .info > .date {
  display: block;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9rem;
}
.list-transactions .item-transaction .box-transaction .info > .amount {
  display: block;
  font-size: 1rem;
  font-family: 'San Francisco', 'Roboto', 'ProductSans', sans-serif;
}
.list-transactions .item-transaction .box-transaction .info > .received {
  color: #49bdf6;
}
.list-transactions .item-transaction .box-transaction > .quota {
  float: right;
  display: block !important;
  text-align: center !important;
  height: 72px;
  padding: 16px 0;
  margin: 0 24px;
  font-family: 'San Francisco', 'Roboto', 'ProductSans', sans-serif;
}
.list-transactions .item-transaction .box-transaction .quota > .title {
  display: block;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1.35rem;
}
.list-transactions .item-transaction .box-transaction .quota > .number {
  display: block;
}

.btn-transactions-more {
  background-color: #f3f2ff !important;
  height: 52px !important;
  color: #3628dd !important;
  width: calc(100% - 32px);
  margin: 0 auto;
  transition: all 0.3s;
  border: none;
}

.btn-transactions-more:hover {
  box-shadow: 0 8px 12px rgba(200, 195, 255, 0.31);
  outline: none;
  border: none;
}

.btn-transactions-more:focus,
.btn-transactions-more:active {
  border: none;
  width: 90%;
  height: 48px;
  box-shadow: 0 12px 24px #d8d5f8 !important;
  outline: none;
}

.shop-list-wrapper {
  padding: 20px;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  min-width: 360px;
  max-width: 450px;
  margin-left: 20px;
  padding-top: 0px;
}
.shop-list-wrapper-cashout {
  overflow-x: auto;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* white-space: nowrap; */
  padding: 20px;
  /* min-width: 360px;
  max-width: 450px; */
  padding-bottom: 2px;
  /* padding-right: 5px; */
  text-align: center;
  padding-top: 4px;
}

.shop-item-warpper {
  border-radius: 16px;
  padding: 16px;
  margin-right: 8px;
  border: 1px solid rgba(54, 40, 221, 0.6);
  width: 112px;
}

.shop-item-container {
  background-color: rgba(54, 40, 221, 0.1);
  width: 50px;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-item-icon {
  font-size: 40px;
  color: rgba(54, 40, 221, 0.8);
}

.shop-item-text {
  font-size: 13px;
  text-align: center;
  margin-top: 12px;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #3628dd;
  text-transform: capitalize;
}

.dashboard-shortcut__button {
  display: flex;
  flex-direction: column;
}

.dashboard-shortcut__img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: solid white 1px;
  border-radius: 50%;
  width: 4em;
  height: 4em;
  background-color: #5e9f72;
  
}

.dashboard-shortcut__img-container:hover {
  cursor: pointer;
}

.dashboard-sortcut__text-container {
  margin: auto;
  color: #3a4d9f;
  font-size: 1em;
  font-weight: bold;
  padding: 5px 0;
}

@media (max-width: 575.98px) {
  .list-transactions .item-transaction .box-transaction > .box-img {
    display: none;
  }

  .dashboard-shortcut__buton {
    display: inline;
  }
  .dashboard-sortcut__text-container {
    text-align: center;
    margin: auto;
  }
}
.lenghtSelect {
  width: 80%;
}

.wallet-section {
  border-radius: 25px;
  background-color: #3a4d9f;
  color: white;
  text-align: center;
}

.wallet-amount {
  font-size: 1.5em;
  padding: 0.3em 0;
}

.wallet-currency {
  font-size: 1.2em;
  background-color: #5e9f72;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.activity-link {
  color: white;
  background-color: #8392d2;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 0.1em 0;
}

.grid-small-dashboard {
  margin-top: 1em;
}

.more-activities {
  /* margin-top: 0.5em; */
}

.logo-icon {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(196deg) brightness(105%) contrast(105%);
  /* filter: invert(43%) sepia(20%) saturate(729%) hue-rotate(85deg) brightness(93%) contrast(90%); */
  max-width: 45%;
  max-height: auto;
}
