
.iso-login {
  display: block;
  margin: 2rem auto 1rem auto;
}

.card-title {
  display: block;
  text-align: center;
  margin-bottom: 3rem;
}

.form-group-login label {
  display: block;
  margin-bottom: -1.5rem;
  opacity: 0;
}
.grecaptcha-badge{
  display: none !important;
}

.form-group-login .form-control:focus .form-group-login > label,
.form-group-login .form-control:active .form-group-login > label {
  margin-bottom: 0.5rem !important;
  opacity: 1 !important;
}

.form-group-login .form-control {
  padding: 0.85rem;
  background-color: #f3f2ff;
  border-color: #f3f2ff;
}

.form-group-login .form-control:focus {
  color: rgba(0, 0, 0, 0.8);
  background-color: #fff;
  border-color: #cbcbff;
  outline: 0;
  box-shadow: 0 8px 20px rgb(244, 242, 255);
}

.form-group-login > input::placeholder {
  color: #948cee !important;
}

.modal-content {
  border-radius: 16px !important;
  color: #3728de !important;
  background-color: #f9f9fe !important;
  transition: all 0.3s ease-in-out !important;
  border-color: transparent;
  
}

.modal-body {
  margin: auto 10px !important;
}

.input-error {
  font-size: 11px;
  color: crimson;
  padding-left: 16px;
  margin-bottom: 0;
}
.input-success {
  font-size: 11px;
  color: green;
  padding-left: 16px;
}
.img-rotate{ 
  width: 80px;
  height: 80px;
  position: relative;
  top:0; right:0; bottom:0;
  animation: rotation 1s infinite linear; 
}
@keyframes rotation {
  100%{ transform:rotatey(360deg); }
}
