
.list-palta {
    display: block;
    height: auto;
    min-height: 72px;
    padding-bottom: rem;
    list-style-type: none;
    -webkit-margin-start: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-padding-start: 0;
}