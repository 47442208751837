.paid-loan-button {
    padding: 8px 12px;
    margin-bottom: 24px;
    margin-top: -24px;
    border-radius: 24px;
    color: #FFFFFF;
    background-color: #02B875;
}

.paid-loan-button.not-paid {
    background-color: #17a2b8;
}

.paid-loan-button.not-paid:hover {
    background-color: #02B875;
    cursor: pointer;
}

.paid-loan-button-text {
    display: block;
    float: right;
    height: 16px;
    margin: 4px 8px;
    line-height: 100%;
    text-transform: uppercase;
    font-size: .95rem;
    font-family: 'ProductSansBold', sans-serif;
    letter-spacing: .08rem;
}