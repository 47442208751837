.data-label {
  font-size: 20px;
  color: rgba(20, 20, 20, 0.6);
}

.data {
  font-size: 16px;
  color: rgba(20, 20, 20, 0.8);
  margin-bottom: 16px;
}

.dolar-color {
  color: 'red'
}
