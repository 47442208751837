.search-heading {
  text-align: center;
  margin-top: 0.5em;
  background-color: #5e9f72;
  padding: 1em;
  color: white;
}

h4 {
  font-size: 1.8em;
}

.payment-info-heading {
  margin-bottom: 1em;
}

.input-empresa {
  width: 50%;
}

.center-content {
  text-align: center;
}

.button-a {
  background-color: #3a4d9f;
  color: white;
  border: 0px;
  border-radius: 4px;
  padding: 1em 3em;
  margin: 0.8em auto;
  text-align: center;
  text-transform: uppercase;
}

.top-pick-business {
  box-sizing: border-box;
  margin: 2px;
  background-color: #3a4d9f;
  color: white;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
}

.list-result {
  display: flex;
  flex-flow: row wrap;
}

.business-card {
  background-color: #3a4d9f;
  color: white;
  font-weight: bold;
  letter-spacing: 0.09em;
  padding: 0.8em 1em;
  display: block;
  text-align: left;
  text-transform: uppercase;
  border-radius: 7px;
  margin: 10px 1em;
  flex-basis: 47%;
}

.business-card:hover {
  background-color: #5e9f72;
  color: white;
}

.business-card-heading {
  border-radius: 7px;
}

.business-card-heading span {
  padding: 10px;
  border-radius: 7px;
  background-color: #5e9f72;
}

hr {
  border-top: solid 2px #3a4d9f;
}

.phone__container {
  text-align: center;
  border: 1px solid #3a4d9f;
  border-radius: 20px;
  /* padding: 2px;
  padding-top: 30px; */
  margin: 5px;
}
.phone__container:hover{
  transition: 0.4s;
  cursor: pointer;
  background-color: #c6dfcd;
}

.phone-image__container {
  height: 4em;
  margin-bottom: 25px;
}

.phone-image__img {
  max-height: 100%;
  margin-top: 10px;
  background-color: white;
  border-radius: 10px;
}

.company-shadow {
  background-color: #a2c8ad;
}

.phone-amount__container {
  padding: 1em;
  margin: 0.3em;
  text-align: center;
  background-color: #3a4d9f;
  color: white;
  font-size: 1em;
  border-radius: 18px;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
}

.flex-item-full {
  flex: 100%;
}

.flex-item-child {
  flex: 100%;
}

.button {
  background-color: #3a4d9f;
  color: white;
  border: 0px;
  border-radius: 16px;
  padding: 1em 3em;
  margin: 1.5em auto;
  text-align: center;
  text-transform: uppercase;
}
.button-disabled{
  background-color: #808cc0;
  color: white;
  border: 0px;
  border-radius: 16px;
  padding: 1em 3em;
  margin: 1.5em auto;
  text-align: center;
  text-transform: uppercase;
  cursor: not-allowed;
}

.phone-button:disabled,
.phone-button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

#heading {
  padding: 0;
  margin: 0 10em;
}
.payment-container-child {
  margin-top: 0em;
  color: white;
  padding: 1em;
  text-transform: uppercase;
  flex: 100%;
}

.payment-container-child-heading {
  background-color: #5e9f72;
  text-align: center;
  text-transform: none;
}

.payment-container {
  margin: 0 10em;
  display: flex;
  flex-flow: row wrap;
  background-color: #3a4d9f;
  padding-bottom: 2em;
  padding-top: 0;
}

.captar-wallet-amount {
  font-size: 1.5em;
  padding: 0.3em 0;
  background-color: white;
  color: black;
  border: 1px solid black;
  border-top: 0px;
  margin-bottom: 1em;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.captar-wallet-currency {
  border: 1px solid black;
  border-bottom: 0px;
  font-size: 1.2em;
  background-color: #5e9f72;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0.2em 0;
}

.captar-wallet-currency.captar {
  padding-left: 1em;
}

.captar-wallet-amount.captar {
  padding-left: 1em;
}

.captar-wallet {
  width: 90%;
}

select {
  width: 90%;
}

.topup-container {
  width: 50%;
  margin: 0 auto;
}

.company-topup__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.phone-personal {
  margin-top: '30px';
  max-width: '90%';
  padding: '7px 7px';
  border-radius: '7px';
  background-color: 'black';
}

@media only screen and (max-width: 600px) {
  .phone-personal {
    margin-top: '35px';
  }

  .input-empresa {
    width: 100%;
  }
  .company-topup__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  #heading {
    margin: 0;
  }
  .top-pick-business {
    flex: 40%;
  }
  .topup-container {
    width: 100%;
    margin: 0 0;
  }
  .business-card {
    flex-basis: 97%;
  }

  .payment-container-child {
    flex: 100%;
  }

  .payment-container {
    display: flex;
    flex-flow: row wrap;
    background-color: #3a4d9f;
    padding-bottom: 2em;
    padding-top: 0;
    margin: 0;
  }
}

.business-info {
  text-transform: capitalize;
  margin-left: 1em;
  margin-top: 1em;
}

.service-container {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr;
  text-align: center;
}

/* pagina 1 */
/* --------- */

.payment-info-container {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 2em;

  padding-left: '1em';
  padding-top: '1em';
}

.payment-data-input {
  width: 25em;
}

.payment-data-label {
  /* margin-top: 0.4em; */
  margin-right: 1em;
  font-size: 1.5em;
}

.rounded-top {
  border-top-left-radius: 25px;
  border-top-left-radius: 25px;
}

.rounded-bottom {
  border-bottom-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.button-opcion {
  margin: 1em auto;
}

.success-subheading {
  color: #155724;
  padding-top: 0.9em;
}

.success-body {
  color: #155724;
  /* padding-top: .9em; */
}

.button-payment-input {
  text-align: center;
  width: 100%;
  height: 7em;
  font-size: 1.2em;
  border-radius: 20px;
}

.choose-img {
  width: 40px;
  margin: 10px;
  filter: invert(99%) sepia(4%) saturate(110%) hue-rotate(225deg)
    brightness(118%) contrast(100%);
}

.input-payment-message {
  font-size: 1.3em;
}
