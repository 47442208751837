.caja4{
    background-color: #3498DB;
    -webkit-transition: 1s linear;
    transition: 1s linear;
  }
  .caja4:hover{
    line-height: 300px;
  }
  .caja{
    width: 180px;
    height: 180px;
    background-color: #30B037;
    color: #FFF;
    text-align: center;
    line-height: 180px;
    margin: 15px;
    float: left;
    font-size: 18px;
    font-family: Arial;
  }

  .butNext {
    background-color: #3A4D9F;
    color: white;
    transition: all 0.3s ease;
    border-color: white !important;
    border: 3px solid white !important;
    font-size: 15px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding-left: 0.4rem !important

  }
  
  .butNext:hover {
    box-shadow: 0 2px #666;
    transform: translateY(4px);
    color: white !important;
    border-radius: 50%;
  }